import React, { useEffect } from 'react';

import data from './data-cy.json';
import Panel from './Panel';

const Cymraeg = () => {
  
    const gtmConfig = 'https://www.principality.co.uk';
  
    useEffect(() => {
      window.parent.postMessage('pageview~/virtual/cy/principality/moving-checklist', gtmConfig);
  
      setInterval(() => {
        window.parent.postMessage(document.body.scrollHeight, gtmConfig);
      }, 250);
    }, []);
  
    return (
  
      <div className="App">
        <div className="checklist">
          <h1>Rhestr wirio ar gyfer symud cartref</h1>
          <div className="intro">
            <p>Mae symud i mewn i’ch cartref cyntaf yn garreg filltir gyffrous. Fodd bynnag, gyda’r rhestri hir o bethau i’w gwneud cyn symud, gall y straen fod yn drech na’r cyffro.</p>
            <p>Dilynwch ein rhestr wirio ar gyfer symud cartref i gadw trefn yn ystod yr wythnosau sy’n arwain at symud.</p>
          </div>
  
          <div className="panelsWrapper">
  
            <Panel
              title="8 wythnos tan y diwrnod symud"
              data={data.panelOneData}
              panelKey={'MHCpanelOneCY'}
              langCode={'/cy/'}
            >
              <p>Rydych wedi dod o hyd i gartref cyntaf eich breuddwydion ac mae’ch cynnig wedi’i dderbyn. Mae symud yn teimlo ymhell i ffwrdd ond mae’n talu ffordd i baratoi.</p>
            </Panel>
  
            <Panel
              title="6 wythnos tan y diwrnod symud"
              data={data.panelTwoData}
              panelKey={'MHCpanelTwoCY'}
              langCode={'/cy/'}
            >
              <p>Erbyn hyn, mae’n debygol eich bod ymhell ar eich ffordd i gwblhau'r broses o brynu’r tŷ a byddwch yn gweld pen y daith. Ond nawr, mae’n amser dechrau meddwl am bacio a chael gwared ar bethau diangen.</p>
            </Panel>
  
            <Panel
              title="4 wythnos tan y diwrnod symud"
              data={data.panelThreeData}
              panelKey={'MHCpanelThreeCy'}
              langCode={'/cy/'}
            >
              <p>Mis i fynd, ac mae’r cyfan yn dechrau teimlo’n real iawn. Mae digon i’w wneud o hyd:</p>
            </Panel>
  
            <Panel
              title="2 wythnos tan y diwrnod symud"
              data={data.panelFourData}
              panelKey={'MHCpanelFourCY'}
              langCode={'/cy/'}
            >
              <p>Cyn pen dim byddwch chi yn eich cartref newydd. Cyn symud, mae nifer o dasgau gweinyddol y dylech eu gwneud:</p>
            </Panel>
  
            <Panel
              title="1 wythnos tan y diwrnod symud"
              data={data.panelFiveData}
              panelKey={'MHCpanelFiveCY'}
              langCode={'/cy/'}
            >
              <p>Wythnos i fynd tan y diwrnod symud, ac os yw’r broses brynu yn gyflawn (byddai angen yr arian ar eich cyfreithiwr gan eich benthyciwr a chanlyniadau’r holl chwiliadau i wneud hyn), mae’n amser cytuno ar ddyddiad i gasglu eich allweddi newydd.</p>
            </Panel>
  
            <Panel
              title="1 diwrnod i fynd"
              data={data.panelSixData}
              panelKey={'MHCpanelSixCY'}
              langCode={'/cy/'}
            >
              <p>Eich noson olaf yn eich hen gartref. Erbyn hyn, dylai’r rhan fwyaf o’r paratoi fod wedi’i wneud, ond mae rhai pethau y gallwch eu gwneud i hwyluso’r symud yfory cymaint â phosibl</p>
            </Panel>
  
            <Panel
              title="Y diwrnod symud"
              data={data.panelSevenData}
              panelKey={'MHCpanelSevenCY'}
              langCode={'/cy/'}
            >
              <p>Diwrnod symud hapus i chi! Mae’r amser wedi dod o’r diwedd i symud i’ch eiddo newydd. Dyma beth ddylech ei wneud:</p>
  
            </Panel>
  
          </div>
        </div>
      </div>
    )
  };
  
export default Cymraeg;