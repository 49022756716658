import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import './App.css';

import English from './English';
import Cymraeg from './Cymraeg';;

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <English/>
        </Route>
        <Route path="/cy">
          <Cymraeg/>
        </Route>
      </Switch>
    </Router>
  )
};

export default App;