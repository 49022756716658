import React, { useState, useEffect, useRef } from 'react';

const Panel = ({ data, panelKey, langCode, title, children }) => {

  const lang = langCode ? langCode : '';

  const [sectionActive, setSectionActive] = useState(false);
  const [panel, setPanel] = useState(localStorage.setItem(panelKey, false));

  // Persist state in localStorage and react state
  const useStickyState = (defaultValue, key) => {
    const [checkList, setCheckList] = useState(() => {
      const stickyValue = localStorage.getItem(key);
      return stickyValue !== null
        ? JSON.parse(stickyValue)
        : defaultValue;
    });
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(checkList));
    }, [key, checkList]);
    return [checkList, setCheckList];
  }

  let [checkList, setCheckList] = useStickyState(data, 'checkList' + panelKey);

  // GTM
  const gtmConfig = 'https://www.principality.co.uk';

  // Toggle complete checkbox
  const handleChecked = (index) => {
    setCheckList(
      checkList.map(i => ({
        ...i, ...(i.id === index && (
          { complete: !i.complete }
          || {}) // Toggle checked
        )
      })))

    checkList.map(i => ({
      ...i, ...(i.id === index && (
        window.parent.postMessage('event~' + lang + 'MovingChecklist~' + panelKey + '_' + index + (i.complete ? '~unchecked' : '~checked'), gtmConfig)
      ))
    }))
  }

  // If every checkbox complete, close panel
  useEffect(() => {
    if (checkList.every(i => i.complete)) {
      setSectionActive(false);
      setPanel(true);
      window.parent.postMessage('event~MovingChecklist~panel' + panelKey + '~complete', gtmConfig)
    } else {
      setPanel(false);
    }
    // eslint-disable-next-line
  }, [checkList]);

  const panelRef = useRef();

  return (
    <div className="panel" ref={panelRef}>
      <button
        onClick={() => { setSectionActive(!sectionActive) }}
        className={
          `button 
          ${panel ? 'completed' : 'not-completed'} 
          dropdown ${sectionActive ? "is-active" : 'is-inactive'}`}
      >
        {title}
      </button>

      <section className={sectionActive ? "is-active" : 'is-inactive'}>

        {children}

        {checkList.map((checkBox) => (
          <div key={checkBox.id}>
            <label
              className="checkBox-wrapper"
              index={checkBox.id}
              onChange={() => handleChecked(checkBox.id)}
            >

              <input
                type="checkbox"
                defaultChecked={checkBox.complete ? true : false}
              />

              <span className="checkBox"></span>
              <div className="checkBox-text">
                {checkBox.text}
              </div>

              {checkBox.url &&
                <div className="linksBar">
                  <a rel="noopener noreferrer" target="_blank" href={checkBox.url}>{checkBox.urlText}</a>
                </div>
              }
            </label>
          </div>
        ))}

        {/* Footer content */}
        {checkList.map((checkBox, id) => (
          <h2 key={id}>
            {checkBox.footer}
          </h2>
        ))}

      </section>
    </div>
  )
}

export default Panel;