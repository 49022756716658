import React, { useEffect } from 'react';

import data from './data-en.json';
import Panel from './Panel';

const English = () => {

  const gtmConfig = 'https://www.principality.co.uk';

  useEffect(() => {
    window.parent.postMessage('pageview~/virtual/principality/moving-checklist', gtmConfig);

    setInterval(() => {
      window.parent.postMessage(document.body.scrollHeight, gtmConfig);
    }, 250);
  }, []);

  return (
    <div className="App">
      <div className="checklist">
        <h1>Moving home checklist</h1>
        <div className="intro">
          <p>Moving into your first home is an exciting milestone. However, with a list full of things to do before the big move, the excitement can be overshadowed by stress.</p>
          <p>Follow our moving home checklist to stay organised in the weeks leading up to your move.</p>
        </div>

        <div className="panelsWrapper">

          <Panel
            title="8 weeks until moving day"
            data={data.panelOneData}
            panelKey={'MHCpanelOneEN'}
          >
            <p>You’ve found your dream first home and had your offer accepted. Moving may still feel like a long way off, but it pays to be prepared. Here are a few steps you can take to get ahead:</p>
          </Panel>

          <Panel
            title="6 weeks until moving day"
            data={data.panelTwoData}
            panelKey={'MHCpanelTwoEN'}
          >
            <p>By this point, you will likely be well on your way to completing your home purchase and the end will be in sight. But for now, it’s time to start thinking about packing and decluttering.</p>
          </Panel>

          <Panel
            title="4 weeks until moving day"
            data={data.panelThreeData}
            panelKey={'MHCpanelThreeEN'}
          >
            <p>With just a month to go, it all starts to feel very real. There’s still plenty to do.</p>
          </Panel>

          <Panel
            title="2 weeks until moving day"
            data={data.panelFourData}
            panelKey={'MHCpanelFourEN'}
          >
            <p>Not long now until you are in your new home. Before moving, there are a number of admin tasks you should complete:</p>
          </Panel>

          <Panel
            title="1 week until moving day"
            data={data.panelFiveData}
            panelKey={'MHCpanelFiveEN'}
          >
            <p>With just a week to go until moving day, if your purchase is complete <em>(your solicitor would need to have the money from your lender and the results of all searches in order to do this)</em>, it’s time to agree a date to collect your new keys.</p>
          </Panel>

          <Panel
            title="1 day to go"
            data={data.panelSixData}
            panelKey={'MHCpanelSixEN'}
          >
            <p>Your last night in your old home. By this time, most preparations and packing should be done, but there are a few things you can do to make tomorrow&#39;s move as easy as possible.</p>
          </Panel>

          <Panel
            title="Moving day"
            data={data.panelSevenData}
            panelKey={'MHCpanelSevenEN'}
          >
            <p>Happy moving day! The time has finally come to move into your new property. Here’s what you should do:</p>

          </Panel>

        </div>
      </div>
    </div>
  )
};

export default English;